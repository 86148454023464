import * as React from "react";
import styled from "@emotion/styled";
import Header, {HeaderNav} from "../header";
import Footer from "../footer";
import { Spin } from "antd";


const Wrapper = styled.div`
  padding: 52px 0;
  min-height: calc(100vh - 148px);
  background-color: #F9F9F9;
`

const PageWrapper = ({authorized = false, selected = HeaderNav.None, loading = false, children, ...props}) => {
  return (<>
    <Header {...{selected, authorized}} />
    <Wrapper {...props}>
      {
        loading && (
          <div style={{textAlign: 'center'}}><Spin/></div>
        ) || (
          children
        )
      }
    </Wrapper>
    <Footer />
  </>)
}

export default PageWrapper
