import * as React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  
  padding: 12px 32px 32px;
  margin: 16px auto 0;
  width: ${(props: any) => props.width}px;
  background: #ffffff;
  border-radius: 8px;
`

const Header = styled.div`
  height: 62px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`

const Title = styled.div`
  line-height: 62px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Content = styled.div`
  padding-top: 20px;
  ${(props: any) => !!props.flex ? "display: flex;" : "" }
  ${(props: any) => !!props.direction ? `flex-direction:${props.direction};` : "" }
  ${(props: any) => !!props.gap ? `gap:${props.gap}px;` : "" }
`

const TeamCard = (props) => {
  const {title = null, header = null, children, flex = false, gap = 0, direction = 'column', contentProps = {}, ...containerProps} = props;
  return (<>
    <Container {...containerProps}>
      {
        (title || header) && (
          <Header>
            {header && header}
            {!header && (
              <Title>{title}</Title>
            )}
          </Header>
        )
      }

      <Content
        {...{flex, gap, direction, ...contentProps}}
      >
        {children}
      </Content>
    </Container>
  </>)
}

export default TeamCard;
