import escapeHtml from "escape-html"
import {Node, Text} from "slate"

const Serialize = (node: any) => {
  let style = 'min-height:1.5em;';
  if (Text.isText(node)) {
    let h = escapeHtml(node.text)
    // @ts-ignore
    if (node.italic) {
      h = `<i>${h}</i>`
    }
    // @ts-ignore
    if (node.underline) {
      h = `<u>${h}</u>`
    }
    // @ts-ignore
    if (node.bold) {
      h = `<strong>${h}</strong>`;
    }
    return h;
  }

  if ('align' in node) {
    style += `text-align:${node.align};`
  }

  const children = node.children.map(n => Serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><div>${children}</div></blockquote>`
    case 'paragraph':
      return `<div style='${style}'>${children}</div>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'image':
      return `<img src="${node.url}" style="width: 100%;"/>`
    default:
      return children
  }
}

export default Serialize

