import * as React from "react";
import PageWrapper from "../../../components/pages/PageWrapper";
import TeamCard from "../../../components/pages/team/TeamCard";
import styled from "@emotion/styled";
import TeamCase from "../../../components/pages/team/TeamCase";
import * as queryString from "query-string"
import {useEffect, useMemo, useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import {message} from "antd";
import Serialize from "../../../components/editor/serialize";
import Seo from "../../../components/seo.js"

const bodyWidth = 1000

const Header = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  line-height: 22px;
  font-size: 20px;
  font-family: PingFang SC-Heavy, PingFang SC, sans-serif;
  font-weight: 800;
  color: rgba(0,0,0,0.8);
`

const Back = styled.a`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  & img {
    padding: 0 0 2px;
    margin: 0 0 0 6px;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
`

const Content = styled.div`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  line-height: 24px;
`

const Case = ({location}) => {

  const params = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const load = () => {
    if (!params.id || loading) {
      return ;
    }
    setLoading(true)
    jsonRequest(`/case/${params.id}`).then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    load()
  }, [params])

  return (<>
    <PageWrapper loading={loading}>
      {data && (
        <>
          <TeamCard
            header={(
              <Header>
                <Name>{data.name}</Name>
                <Back href={`/team?id=${data.userId}`}>返回<img src="/images/icons/right.png" alt=""/></Back>
              </Header>
            )}
            width={bodyWidth}
          >
            <Content dangerouslySetInnerHTML={{__html: JSON.parse(data.content).map(n => Serialize(n)).join('')}}>
              {/*的确，极简主义一直在某种程度上流行，但是 2022 年出现的新极简主义的浪潮，比起传统的极简主义设计更加在意用户注意力的吸引。这种新极简主义的设计会采用更加大胆、 生动、明亮的色彩来填补留白，这种设计使得设计保持简约的同时，更加富有调性，在简干净清晰、生动的新极简主义风格在品牌推广的过程中也更加受欢迎，大量的留白和跳脱的视觉焦点让整个视觉更加抓人眼球，也使得品牌给人的感知更加新鲜独特。新极简主义风格，对比度在深色主题下得到了进一步的提升这种设计并不意味着传统的极简主义已经被用户抛弃，很多企业依然会青睐更加传统的单色极简主义设计，不过其中多少会增加一些明亮的色彩作为点缀。从 1950 年开始，粗野主义就是年轻化、反叛、反主流、标新立异的设计风格。在过去的几十年当中，这种设计风格一直随着时代的发展而自我迭代，新粗野主义更加强调创造力、 大胆前卫的新奇元素，而这也正好符合很多品牌对于新奇性的追求，它可以瞬间抓住用户的注意力。不过，这种风格的使用需要控制好程度，否则很容易失控。 粗野主义经久不衰的秘诀是什么？是未经打磨、粗粝而原始的素材，它让观看者忍不住想要改进它，这种未完成的原始感会在情感上*/}
              {/*<DefaultElement element={JSON.parse(data.content)} attributes={null} />*/}
            </Content>
          </TeamCard>
          <TeamCard
            title='项目案例'
            width={bodyWidth}
            flex gap={33}
            direction='row'
            contentProps={{
              style: {
                flexWrap: 'wrap'
              }
            }}
          >
            {data.cases?.map((c) => (
              <TeamCase
                key={c.id}
                width="calc(33.3% - 22px)"
                href={`/team/case?id=${c.id}`}
                cover={c.coverUrl}
                {...c}
              />
            ))}
          </TeamCard>
        </>
      )}
    </PageWrapper>
  </>)
}

export const Head = () => <Seo />

export default Case
